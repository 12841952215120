<template>
  <div class="c-header-lv1" :class="{ on: hShow }" @touchmove.prevent>
    <!-- logo -->

    <div class="header">
      <div class="logo" @click="onPageToHome">
        <c-image iUrl="logo_s" iWidth="46.3" iHeight="22"></c-image>
      </div>

      <div class="right">
        <!-- search -->
        <div class="search">
            <div class="search-input">
                <input type="text" v-model="search" name="headerSearch" placeholder="搜索作品或编号"  autocomplete="off" @focus="onFocus" @input="onInput" @keyup.enter="onPageToProducts(search)"  ref="sInput"/>
            </div>
          
          <div class="btn" @click="onPageToProducts(search)">
            <c-image iUrl="icon-search_s" iWidth="16" iHeight="16"></c-image>
          </div>
        </div>

        <!-- menu -->
        <template v-if="isLogin">
            <div class="menu" v-if="!showClose" @click="onOpenNav">
                <div class="avatar">
                    <van-image :src="userData.user.head_img" :width="26 / 37.5 + 'rem'" :height="26 / 37.5 + 'rem'" fit="cover" loading-icon="" error-icon=""></van-image>
                </div>
            </div>
            <div class="menu" v-else @click="onClose">
                <c-image iUrl="icon-close" iWidth="12" iHeight="12"></c-image>
            </div>
        </template>
        <template v-else>
            <div class="menu" v-if="!showClose" @click="onPageToLogin">
                <c-image iUrl="icon-person" iWidth="22" iHeight="22"></c-image>
            </div>
            <div class="menu" v-else @click="onClose">
                <c-image iUrl="icon-close" iWidth="12" iHeight="12"></c-image>
            </div>
        </template>
        

        
      </div>
    </div>

    <!-- nav -->
    <div class="nav" :class="{ on: showNav }" >
        <!-- user info -->
        <div class="nav_top">
            <div class="nav_top_container">
                <div class="nav_user_info">
                    <div class="nav_info">
                        <div class="nav_user">
                            <h4>{{userData.user.nickname}}</h4>
                            <!-- <div class="nav_user_tag">
                                <c-image :iUrl="'icon-vip_'+userData.user.user_level" iClass="tag_item" iWidth="24" iHeight="20"></c-image>
                                <c-image iUrl="icon-original" iClass="tag_item" iWidth="24" iHeight="20" v-if="userData.user.bonus_level>100"></c-image>
                            </div> -->
                        </div>
                        <div class="nav_desc">ID: {{userData.user.id}}</div>
                    </div>
                    <div class="nav_avatar">
                        <van-image :src="userData.user.head_img" :width="48 / 37.5 + 'rem'" :height="48 / 37.5 + 'rem'" fit="cover" loading-icon="" error-icon=""></van-image>
                        <c-image iUrl="icon-vip_s" iClass="vip" iWidth="18" iHeight="18" v-if="userData.vip_flag"></c-image>
                    </div>
                </div>
                <div class="nav_data">
                    <div class="nav_data_item">
                        <div class="num">{{userData.work_num}}</div>
                        <div class="name">作品</div>
                    </div>
                    <div class="nav_data_item">
                        <div class="num">{{totalAmount}}</div>
                        <div class="name">提现</div>
                    </div>
                    <div class="nav_data_item">
                        <div class="num">{{userData.collect_num}}</div>
                        <div class="name">收藏</div>
                    </div>
                    <div class="nav_data_item">
                        <div class="num">{{userData.invite_num}}</div>
                        <div class="name">邀请</div>
                    </div>
                </div>
            </div>
        </div>

        <!-- nav list -->
        <div class="nav_list">
            <div class="nav_item" v-for="item in menus" :key="item.name" @click="onNavToPage(item.route)">
                <div class="content">
                    <div class="left">
                    <c-image :iUrl="item.img" iWidth="38" iHeight="38"></c-image>
                    <p>{{ item.name }}</p>
                    </div>
                    <c-image iUrl="icon-right" iWidth="7" iHeight="13"></c-image>
                </div>
            </div>
        </div>

        <!-- btn -->
        <div class="btn_exit" @click="onExit">退出登录</div>
    
    </div>

    <!-- tag -->
    <div class="tag" :class="{ on: showTag }">
        <div class="tag_title" v-if="cookieTags.length > 0">
            <p>最近搜索</p>
            <div class="icon-clear"  @click="onClearCookie">
                <c-image iUrl="icon-delete" iWidth="16" iHeight="16"></c-image>
            </div>
        </div>
        <template v-if="cookieTags.length > 0">
            <div class="tag_item" v-for="(item , index) in cookieTags" :key="index+100" @click="onPageToProducts(item)">{{item}}</div>
        </template>
        <div class="tag_title mt-20">
            <p>热门搜索</p>
        </div>
        <template v-if="hotTags.length > 0">
            <div class="tag_item" v-for="(item , index) in hotTags" :key="index" @click="onPageToProducts(item)">{{item}}</div>
        </template>
    </div>

    <div class="tag-search" :class="{ on: showTagSearch }">
        <template v-if="cookieTagsSearch.length > 0">
            <div class="tag_item" v-for="(item , index) in cookieTagsSearch" :key="index" @click="onPageToProducts(item.fir + item.last)"><span>{{ item.fir }}</span>{{ item.last }}</div>
        </template>
    </div>

  </div>
</template>

<script>
import { Image } from "vant";
import CImage from "../components/CImage.vue";
import { menus } from "../router/config";
import { mapState, mapMutations } from "vuex";
import { dialogAlert2, toastSuccess } from "../lib/util";
import { requestApi_get } from '../lib/api';
export default {
    name: "c-header-lv1",
    components: {
        [CImage.name]: CImage,
        [Image.name]: Image,
    },
    props: {
        hShow: {
            type: Boolean,
            required: true,
            default: false,
        },
        hotTags: {
            type: Array,
            required: true,
            default: () => [],
        },
    },
    data: function () {
        return {
            cookieTags: [],
            cookieTagsSearch: [],
            menus: menus,
            showClose: false,
            showTag: false,
            showTagSearch: false,
            showNav: false,
            search: "",

            nextRouteInMe: '',  //在主页跳下一个页面路由名称
        };
    },
    computed: {
        ...mapState({
            routeName: (state) => state.routeName,  //当前路由名称
            keywords: (state) => state.search.keyWords, //缓存关键词
            userData: (state) => state.me.userData,//用户信息
            historyKeyWords: (state) => state.search.historyKeyWords,   //缓存历史关键词
            os: (state) => state.os,    //设备信息
            isLogin: state => state.isLogin,    //用户是否登录
            headerNewsMe: state => state.headerNewsMe,  //header和me之间通信
            homeScrollTop: state => state.homeScrollTop //首页滚动高度
        }),
        totalAmount() {
            return parseInt(this.userData.user.total_withdraw_amount)
        }
    },
    watch: {
        routeName(val) {
            this.showClose = false;
            this.showTag = false;
            this.showNav = false;

            //当前页是搜索列表也，获取并显示历史搜索关键词
            if (val == "products") {
                this.search = this.historyKeyWords;
                this.setKeywords(this.historyKeyWords);
            }
            if (val == 'home2') {
                this.onFocus();
                this.OnClickInput();
            }
        },
        keywords(val) {
            this.search = val;
        },
        headerNewsMe(val) {     //me给header发送通讯，跳转下一个路由，并销毁通讯
            if (val == 'route') {
                if (this.nextRouteInMe == 'home') {
                    this.$router.push({ name: "home" });
                } else if (this.nextRouteInMe == 'homepage') {
                    this.$router.push({
                        name: "homepage",
                        params: { id: this.$cookies.get("user_id"), clickFrom: "nav", enterType: "click" },
                        query: { tab: 0 }
                    });
                } else {
                    this.$router.push({
                        name: this.nextRouteInMe,
                        params: {
                            clickFrom: "nav",
                        },
                    });
                }
                this.nextRouteInMe = ''
                this.setHeaderNewsMe('');
            }
        }
    },
    methods: {
        ...mapMutations({
            setKeywords: "search/SET_KEY_WORDS",
            setReloadSearch: "search/SET_RELOAD_SEARCH",
            setIsLogin: "SET_IS_LOGIN",
            setHeaderNewsMe: "SET_HEADER_NEWS_ME",
            setReloadMe: "SET_RELOAD_ME"
        }),
        onPageToLogin() {
            this.$router.push({
                name: "login",
            });
        },
        onPageToHome() {
            if (this.routeName == 'homepage') {     //如果当前页是homepage,记录下一个路由，进行header与me通信，保存homepage页面数据
                this.nextRouteInMe = 'home';
                this.setHeaderNewsMe('save')
            } else {
                this.$router.push({
                    name: "home",
                });
            }

            this.showClose = false;
            this.showTag = false;
            this.showNav = false;
        },
        onExit() {
            this.$cookies.remove("user_id");
            this.$cookies.remove("invite_code");

            this.showClose = false;
            this.showTag = false;
            this.showNav = false;

            this.setIsLogin(false);

            this.$router
                .replace({ name: "home" })
                .then(() => {
                    toastSuccess("退出成功");
                })
                .catch((err) => { });

            if (this.$route.name == "home") toastSuccess("退出成功");


        },
        onNavToPage(route) {
            if (route == "homepage") {  // 跳转到我的主页
                if (this.routeName == 'homepage' && this.$route.params.id == this.$cookies.get('user_id')) { //当前为自己的主页，重新刷新页面
                    this.setReloadMe(true);
                } else {
                    if (this.routeName != 'homepage') {  //当前页不是主页，直接跳转到主页
                        this.$router.push({
                            name: "homepage",
                            params: { id: this.$cookies.get("user_id"), clickFrom: "nav", enterType: "click" },
                            query: { tab: 0 }
                        });
                    } else {    //当前为他人主页，记录跳转页面路由，并给me发送通讯
                        this.nextRouteInMe = route;
                        this.setHeaderNewsMe('save')
                    }

                }

            } else {                    // 跳转到其他导航页面
                if (this.routeName == 'homepage') {         //如果当前页是主页,记录跳转页面路由，并给me发送通讯
                    this.nextRouteInMe = route;
                    this.setHeaderNewsMe('save')
                } else {
                    this.$router.push({
                        name: route,
                        params: {
                            clickFrom: "nav",
                        },
                    });
                }

            }

            this.showClose = false;
            this.showNav = false;
        },
        onFocus() {
            this.$emit('focusInput')  
            if (this.os.ios) document.body.scrollTop = this.homeScrollTop    //IOS 12以下环境下打开热门关键词是回到当前滚动位置，解决显示键盘后页面向上滚动问题
            // if (this.os.ios && this.routeName == 'home') document.body.scrollTop = this.homeScrollTop    //IOS 12以下环境下打开热门关键词是回到当前滚动位置，解决显示键盘后页面向上滚动问题
            if(this.$cookies.isKey('searchHistory')) {
                var searchHistory = this.$cookies.get('searchHistory');
                this.cookieTags = searchHistory ? JSON.parse(searchHistory) : []
            }

            this.showClose = true;
            this.onInput()
            this.showNav = false;
        },
        OnClickInput() {  
            this.$refs.sInput.focus();
        },
        onInput() {
            var _word = (this.search).trim();
            this.cookieTagsSearch = [];
            if (_word) {
                requestApi_get('/record/index', {word: _word}).then(res => {
                    // 判断是为了处理 快速删除 数据覆盖得问题
                    if((this.search).trim()) {
                        this.cookieTagsSearch = res
                        this.showTagSearch = true;
                        this.showTag = false;
                    }else {
                        this.showTagSearch = false;
                        this.showTag = true;
                    }
                })
            }else {     // input 空,则显示推荐栏 隐藏搜索词下拉栏
                this.showTagSearch = false;
                this.showTag = true;
            }
        },
        onClose() {
            if (this.routeName == 'home2') {
                this.$router.push({
                    name: "home"
                });
            }

            if (this.os.ios) document.body.scrollTop = this.homeScrollTop
            // if (this.os.ios && this.routeName == 'home') document.body.scrollTop = this.homeScrollTop    //IOS 12以下环境下关闭热门关键词是回到当前滚动位置，解决显示键盘后页面向上滚动问题

            this.showClose = false;
            this.showTag = false;
            this.showTagSearch = false;
            this.showNav = false;

            this.$emit('cancelFocus')
        },
        onClearCookie() {
            this.cookieTags = [];
            this.$cookies.remove('searchHistory');
        },
        onOpenNav() {
            this.showClose = true;
            this.showNav = true;
        },
        onPageToProducts(val) {
            let _routeName = this.$route.name;

            if (val) {
                this.saveHistory(val);
                if (_routeName == "products") {
                    //搜索页搜索不跳转路由，直接查询
                    this.setKeywords(val);
                    this.setReloadSearch(true);
                } else {
                    this.$router.push({
                        name: "products",
                        params: { keywords: val },
                        query: { keywords: val }
                    });
                }
                this.showClose = false;
                this.showTag = false;
                this.showTagSearch = false;
                this.showNav = false;
                this.$refs.sInput.blur();
            } else {
                dialogAlert2("请输入搜索关键词");
            }
        },
       // 存储关键词到cookie
       saveHistory(keywords) {
            // 处理keywords， 转大写/去2边空格/判断特殊词
            keywords = keywords.trim().toUpperCase();

            if(keywords) {
                var searchHistory = this.$cookies.get('searchHistory');
                searchHistory = searchHistory ? JSON.parse(searchHistory) : [];
                // 如果关键词已存在，先移除旧的关键词
                var index = searchHistory.indexOf(keywords);
                if (index !== -1) {
                    searchHistory.splice(index, 1);
                }
                searchHistory.unshift(keywords);
                // 限制搜索历史长度，可以根据需要调整
                if (searchHistory.length > 10) {
                    searchHistory.pop(); // 移除最后一个搜索词
                }
                this.$cookies.set('searchHistory', JSON.stringify(searchHistory),86400*30);
            }
        }
    },
    created() {
        if (this.$cookies.isKey("user_id")) {
            this.setIsLogin(true)
        }
    },
    mounted() {
        if (this.routeName == 'home2') {
            this.onFocus();
            this.$refs.sInput.focus();
        }
    },
    activated() {
        if (this.$cookies.isKey("user_id")) {
            this.setIsLogin(true)
        }
    },
};
</script>

<style>
.c-header-cover{width:100%;height:100%;position:absolute;left:0;top:0;z-index:100}
.c-header-lv1{width:100%;height:47px;background:#fff;position:fixed;left:0;top:-47px;z-index:300;transition:top 0.2s ease-out;transform: translateZ(0);-webkit-transform: translateZ(0);}
.c-header-lv1.on{top:0}
.c-header-lv1 .header{overflow:hidden;background:#fff;padding:0 0 0 12px;width:100%;height:47px;display:flex;justify-content:space-between;align-items:center;position:relative;z-index:300;border-bottom:1px solid #ededed;}
.c-header-lv1 .logo{width:46.3px;height:22px}
.c-header-lv1 .search{width:258px;height:30px;border:1px solid #ededed;border-radius:19px;display:flex;justify-content:space-between;align-items:center;overflow:hidden}
.c-header-lv1 .search-input{width:300px;height:30px; padding: 5px 0;}
.c-header-lv1 .search input{border:none; vertical-align: top; margin:0; padding:0; box-sizing: border-box; text-indent:16px;width:230px;height:20px;color:#2E2E30;line-height:20px;font-size:14px;}
.c-header-lv1 .search input::-webkit-input-placeholder{font-size:14px;color:#b3b3b3;}
.c-header-lv1 .btn{width:30px;height:30px;display:flex;align-items:center;justify-content:center;margin-right:5px}
.c-header-lv1 .menu{width:44px;height:46px;display:flex;align-items:center;justify-content:center}
.c-header-lv1 .avatar{width:26px; height: 26px; border-radius: 50%; overflow: hidden;}
.c-header-lv1 .right{display:flex;align-items:center;justify-content:flex-end}
.c-header-lv1 .nav{width:100%;height:100vh;background:#f5f5f5;position:absolute;left:0;top:-100vh;z-index:90;transition:top 0.2s ease-out; opacity: 0;}
.c-header-lv1 .nav.on{top:47px; opacity: 1;}
.c-header-lv1 .nav_item{padding:0 20px}
.c-header-lv1 .nav_item .content{width:100%;height:62px;border-bottom:1px solid #ededed;display:flex;justify-content:space-between;align-items:center}
.c-header-lv1 .nav_item .left{display:flex;justify-content:flex-start;align-items:center}
.c-header-lv1 .nav_item .left p{font-size:16px;color:#2e2e30;margin-left:12px;}
.c-header-lv1 .tag{width:100%;height:100vh;background:#f5f5f5;position:absolute;left:0;top:-100vh;z-index:80;padding:20px;display:flex;justify-content:flex-start;flex-wrap:wrap;align-content:flex-start}
.c-header-lv1 .tag.on{top:47px}
.c-header-lv1 .tag .tag_title {display: block;width: 100%;font-size:14px;margin-bottom: 12px;color: #B3B3B3;}
.c-header-lv1 .tag .tag_title.mt-20 {margin-top: 10px;}
.c-header-lv1 .tag .tag_title p{display: inline-block;}
.c-header-lv1 .tag .tag_title .icon-clear {display: inline-block; margin-left: 10px;}
.c-header-lv1 .tag .tag_item{padding:8px 20px;background:#EDEDED;border-radius:4px;font-size:14px;color:#2E2E30;margin-right:12px;margin-bottom:12px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis; max-width: 3rem; width: auto; }
.c-header-lv1 .tag-search{width:100%;height:100vh;background:#f5f5f5;position:absolute;left:0;top:-100vh;z-index:80;padding:10px 20px;}
.c-header-lv1 .tag-search.on{top:47px}
.c-header-lv1 .tag-search .tag_item{font-size: 14px; padding: 10px 0;border-bottom: 1px solid #ededed; color: #2E2E30;}
.c-header-lv1 .tag-search .tag_item span {font-size: 14px; color: #B3B3B3;}
.c-header-lv1 .btn_exit{width:335px; height: 46px; border-radius: 27px; background: #F85659; color: #fff;font-size: 16px; line-height: 46px; text-align: center; margin: 32px auto 0;}
.c-header-lv1 .nav_top{padding: 20px 20px 0;}
.c-header-lv1 .nav_top_container{background:#fff; border-radius:4px ;padding: 20px 20px 16px;}
.c-header-lv1 .nav_user_info{ display: flex; justify-content: space-between; align-items: center;}
.c-header-lv1 .nav_avatar{width:48px; height: 48px; border-radius: 50%; position: relative; z-index: 1;}
.c-header-lv1 .nav_avatar .van-image img {width: 100%; height: 100%; border-radius: 50%;} 
.c-header-lv1 .nav_avatar .vip {position: absolute; position: absolute; right: 0; bottom: 0px;}
.c-header-lv1 .nav_info{flex: 1;}
.c-header-lv1 .nav_user{display: flex; justify-content: flex-start; align-items: center;}
.c-header-lv1 .nav_user h4{display: inline-block; max-width: 160px; margin-right:11px;white-space:nowrap;overflow:hidden;text-overflow:ellipsis; font-size: 20px; color: #2E2E30; font-weight: normal;}
.c-header-lv1 .nav_user_tag{flex:1; display: flex; justify-content: flex-start; align-items: center;}
.c-header-lv1 .nav_user_tag .tag_item{margin-right:8px ;}
.c-header-lv1 .nav_desc{font-size: 12px;color: #B3B3B3; width:230px;white-space:nowrap;overflow:hidden;text-overflow:ellipsis; margin-top: 4px;}
.c-header-lv1 .nav_data{margin-top: 25px; display: flex; justify-content: space-between; align-items: center;}
.c-header-lv1 .nav_data_item .name{font-size: 12px;color: #B3B3B3; text-align: center;}
.c-header-lv1 .nav_data_item .num{font-size: 16px; text-align: center; color:#2E2E30 ; margin-bottom: 4px;}
.c-header-lv1 .nav_data_item .red{color: #F85659;}
</style>