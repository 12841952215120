<template>
  <div class="basic-layout">
    <c-header-lv1 :hShow="showHeader" :hotTags="hotTags" @focusInput="onFocusInput" @cancelFocus="onCancelFocus"></c-header-lv1>

    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
  </div>
</template>

<script>
import CHeaderLv1 from "../CHeaderLv1";
import { mapState, mapMutations } from "vuex";

export default {
    name: "basic-layout",
    components: {
        [CHeaderLv1.name]: CHeaderLv1,
    },
    data() {
        return {
            showHeader: true,
            lastScrollTop: 0, //作品详情页面最后滚动高度
            time: null,

            isFocusInput: false,    //头部导航input是否处于focus
        };
    },
    computed: {
        ...mapState({
            routeName: (state) => state.routeName,
            searchTop: (state) => state.hSearchTop, //首页大搜索框底部到窗口的绝对高度
            hotTags: (state) => state.search.hotKeywords,
        }),
    },
    watch: {
        routeName() {
            // 只允许首页点击大搜索框 调整到home2，其他页面都返回首页
            if(this.routeName=='home2' && this.$route.name!='home') {
                this.$router.push({
                    name: "home",
                });
            }
            
            this.initHeader();
        },
        showHeader(value) {
            this.$store.dispatch("changeHeader", value);
        },
    },
    methods: {
        ...mapMutations({
            setHomeScrollTop: 'SET_HOME_SCROLL_TOP'
        }),
        onCancelFocus() {
            this.isFocusInput = false;
        },
        onFocusInput() {
            this.isFocusInput = true;
            this.showHeader = true;
        },
        onScroll() {
            let _scrollTop =
                window.pageYOffset ||
                document.documentElement.scrollTop ||
                document.body.scrollTop;

            if (this.routeName == "home") {
                if (!this.isFocusInput) {   //如果为true不控制header,防止input为focus时header消失（该处为了iPhoneX以上版本做处理）
                    if (_scrollTop >= this.searchTop) {
                        this.showHeader = true;
                        this.setHomeScrollTop(_scrollTop)   //头部导航显示记录滚动位置
                    } else this.showHeader = false;
                }
            } else if (this.routeName == "detail") {
                let _scroll = _scrollTop - this.lastScrollTop;
                this.lastScrollTop = _scrollTop;

                if (_scrollTop <= 47) {
                    clearTimeout(this.time);
                    this.showHeader = true;
                } else {
                    this.time = setTimeout(() => {
                        clearTimeout(this.time);

                        if (_scroll < 0) this.showHeader = true;
                        else if (_scroll >= 0) this.showHeader = false;

                    }, 250);
                }
            }
        },
        initHeader() {
            if (this.routeName == "home") {
                this.showHeader = false;
            } else {
                if (this.routeName == "detail") this.lastScrollTop = 0;
                this.showHeader = true;
            }
        },
    },
    created() {
        this.initHeader();
        window.addEventListener("scroll", this.onScroll);

        this.$store.dispatch("search/requestHotKeywords");

    },
    mounted() { },
    destroyed() {
        window.removeEventListener("scroll", this.onScroll);
    },
};
</script>

<style>

</style>